import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

export interface MachineSalesFrame {
  time_period: string;
  total_sales: number;
  total_movements: number;
  machine_type: "lavadora" | "secadora";
}

interface Response {
  data: MachineSalesFrame[];
}

interface GetMachineAggregatedSalesByPeriodProps extends BaseProps {
  machineIds: number[] | undefined;
  period?: string;
}

export const getMachineAggregatedSalesByPeriod = async ({
  from,
  to,
  branches,
  machineIds,
  period = "day",
}: GetMachineAggregatedSalesByPeriodProps) => {
  if (!machineIds) {
    return null;
  }

  let url = "/api/v1/machines/aggregate";
  url = addBaseURLParams(url, { from, to, branches });
  url += `&machine_ids=${machineIds.join(",")}`;
  url += `&period=${period}`;
  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });
  return response;
};

export const useGetMachinesAggregatedSalesByPeriod = ({
  branches,
  from,
  to,
  machineIds,
  period,
}: GetMachineAggregatedSalesByPeriodProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      "machines-aggregated-sales-by-period",
      from,
      to,
      branches,
      machineIds,
      period,
    ],
    queryFn: () =>
      getMachineAggregatedSalesByPeriod({
        branches,
        from,
        to,
        machineIds,
        period,
      }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data, isLoading };
};
