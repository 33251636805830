import { BaseProps } from "../../../utils/types";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { SidePanel } from "../../ui/SIdePanel";
import { MachineStatsByPeriodGraph } from "../MachineStatsByPeriodGraph";
import { BasicTabs } from "../../ui/Tabs";
import React from "react";
import { MachineDetailSummary } from "./MachineDetailSummary";
import { useGetMachineStatsByID } from "../../../api/endpoints/get-machine-stats-by-id";

interface MachineDetailProps extends BaseProps {
  id: number | undefined;
  onClose: () => void;
}

export const MachineDetail = ({
  id,
  from,
  to,
  branches,
  onClose,
}: MachineDetailProps) => {
  const [tab, setTab] = React.useState<number>(0);
  const { data, isLoading } = useGetMachineStatsByID({
    id: id ?? 0,
    branches,
    from,
    to,
  });
  return (
    <SidePanel>
      <div
        className="flex my-3 px-4"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div>{data?.machine_name}</div>
        <Button variant="outlined" onClick={onClose} size={"small"}>
          Cerrar
        </Button>
      </div>
      <Divider />
      <div className="p-4">
        <BasicTabs
          tabs={[
            {
              label: "Resumen",
              content: (
                <MachineDetailSummary
                  id={id}
                  data={data}
                  isLoading={isLoading}
                  branches={branches}
                  from={from}
                  to={to}
                />
              ),
            },
            {
              label: "Gráficas",
              content: (
                <MachineStatsByPeriodGraph
                  from={from}
                  to={to}
                  branches={branches}
                  machineIds={[id ?? 0]}
                  width={380}
                />
              ),
            },
          ]}
          value={tab}
          onValueChange={(_, t) => setTab(t)}
        />
      </div>
    </SidePanel>
  );
};
