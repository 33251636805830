import { DateRangePickerValue } from "@tremor/react";
import { useMemo, useState } from "react";
import { CURRENT_DAY, FIRST_DAY_OF_MONTH } from "../utils/date";
import { useUrlParam } from "./use-url-param";
import { useSearchParams } from "react-router-dom";

const TIMEFRAME_PARAM = "timeFrame";

const getTimeFrameValues = (timeFrame: string | null) => {
  const [from, to] = timeFrame?.split(":") ?? [FIRST_DAY_OF_MONTH, CURRENT_DAY];

  const fromDate = new Date(from);
  const toDate = new Date(to);
  return {
    fromParam: new Date(fromDate.setHours(fromDate.getHours() + 6)),
    toParam: new Date(toDate.setHours(toDate.getHours() + 6)),
  };
};

const encodeTimeFrame = (from: string, to: string) => {
  return from + ":" + to;
};

export const useTimeFrameURLParam = () => {
  const [searchParams] = useSearchParams();
  const timeFrameParam = searchParams.get(TIMEFRAME_PARAM);
  const { fromParam, toParam } = useMemo(
    () => getTimeFrameValues(timeFrameParam),
    [timeFrameParam]
  );
  const [timeFrame, setTimeFrame] = useState<DateRangePickerValue>({
    from: fromParam,
    to: toParam,
  });

  const { from, to } = useMemo(() => {
    if (!timeFrame.from) {
      return {
        from: "",
        to: "",
      };
    }

    const from = timeFrame.from.toISOString().split("T")[0];
    const to = timeFrame.to?.toISOString().split("T")[0];

    if (!to) {
      return {
        from,
        to: from,
      };
    }

    return {
      from,
      to,
    };
  }, [timeFrame]);

  useUrlParam(TIMEFRAME_PARAM, encodeTimeFrame(from, to));

  return {
    from,
    to,
    timeFrame,
    onTimeFrameChange: setTimeFrame,
  };
};
