import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";

import { SLOT_PROPS } from "../../../utils/charts";

interface GraphProps {
  data: {
    xAxis: {
      keys: string[];
    }[];
    series: {
      data: number[];
      label?: string;
    }[];
  };
  title: string;
  rightTitleComponent?: React.ReactNode;
  colors?: string[];
  type?: "bar" | "line" | "area";
  width?: number;
  height?: number;
  xAxisValueFormatter?: (value: string) => string;
  seriesValueFormatter?: (value: number | null) => string;
}

export const Graph = ({
  data: { xAxis, series },
  title,
  rightTitleComponent,
  colors,
  type = "bar",
  width,
  height = 350,
  xAxisValueFormatter,
  seriesValueFormatter,
}: GraphProps) => {
  let graph = <></>;

  if (type === "bar") {
    graph = (
      <BarChart
        slotProps={SLOT_PROPS}
        xAxis={xAxis.map((x) => ({
          scaleType: "band",
          data: x.keys,
          valueFormatter: xAxisValueFormatter,
        }))}
        series={series.map((s) => ({
          data: s.data,
          label: s.label,
          valueFormatter: seriesValueFormatter,
        }))}
        grid={{
          horizontal: true,
        }}
        height={height}
        width={width}
        colors={colors}
      />
    );
  } else if (type === "line" || type === "area") {
    graph = (
      <LineChart
        slotProps={SLOT_PROPS}
        xAxis={xAxis.map((x) => ({
          scaleType: "band",
          data: x.keys,
        }))}
        series={series.map((s) => ({
          data: s.data,
          valueFormatter: seriesValueFormatter,
          area: type === "area",
        }))}
        grid={{
          horizontal: true,
        }}
        height={height}
        width={width}
        colors={colors}
      />
    );
  }

  return (
    <div>
      <div className="flex justify-between">
        <h3 className="text-base font-bold"> {title} </h3>
        {rightTitleComponent}
      </div>
      {graph}
    </div>
  );
};
