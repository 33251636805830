export function formatToMXN(number: number | null) {
  if (number === null) {
    return "N/A";
  }

  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: 0,
  }).format(number);
}
