import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

interface Response {
  data: {
    unique_visits: number;
    total_visits: number;
    avg_uses_per_customer: number;
  };
}

export const getCustomerStats = async ({ branches, from, to }: BaseProps) => {
  let url = "/api/v1/customers/stats";
  url = addBaseURLParams(url, { from, to, branches });

  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

interface GetCustomers extends BaseProps {}
export const useGetCustomerStats = ({ branches, from, to }: GetCustomers) => {
  const { data, isLoading } = useQuery({
    queryKey: ["get-customer-stats", branches, from, to],
    queryFn: () => getCustomerStats({ branches, from, to }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data, isLoading };
};
