const options: Intl.DateTimeFormatOptions = {
  timeZone: "America/Mexico_City",
};

export const DAYS = [
  "lunes",
  "martes",
  "miércoles",
  "jueves",
  "viernes",
  "sábado",
  "domingo",
];

// Calculates the number of days between two dates with the format 'YYYY-MM-DD'. The result includes the start and end dates.
export function calculateDaysBetween(from: string, to: string): number {
  // Create Date objects from the input strings
  const fromDate = new Date(from);
  const toDate = new Date(to);

  // Ensure that the dates are valid before calculating
  if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
    throw new Error("Invalid date format");
  }

  // Calculate the difference in milliseconds
  const diffInMilliseconds: number = toDate.getTime() - fromDate.getTime();

  // Convert milliseconds to days
  const diffInDays: number = diffInMilliseconds / (1000 * 60 * 60 * 24);

  return diffInDays + 1;
}

// Returns date formated as YYYY-MM-dd
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = date.toISOString().slice(0, 10);
  return formattedDate;
};

// Formats date to long format
export const formatToLongDate = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
    ...options,
  });
  return formattedDate;
};

export function getDayOfWeek(dateString: string): string {
  // Create a Date object from the dateString
  const date = new Date(dateString);

  // Define options for date formatting
  const opts: Intl.DateTimeFormatOptions = {
    weekday: "long",
    ...options,
  };

  // Use toLocaleDateString with the 'es' locale to get the day of the week in Spanish
  const dayOfWeekInSpanish: string = date.toLocaleDateString("es-ES", opts);

  // Return the day of the week in Spanish
  return dayOfWeekInSpanish;
}

export const getHour = (dateString: string) => {
  // Create a Date object from the original date string
  const date = new Date(dateString);

  date.setHours(date.getHours() + 6); // Add 6 hours

  // Define options for date formatting
  const opts: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    ...options,
  };

  // Use toLocaleDateString with the 'es' locale to get the hour in Spanish
  const hour = date.toLocaleTimeString("es-ES", opts);

  // Return the hour in Spanish
  return hour;
};

export const getMonth = (dateString: string) => {
  // Create a Date object from the original date string
  const date = new Date(dateString);

  // Define options for date formatting
  const opts: Intl.DateTimeFormatOptions = {
    month: "long",
    ...options,
  };

  // Use toLocaleDateString with the 'es' locale to get the month in Spanish
  const month = date.toLocaleDateString("es-ES", opts);

  // Return the month in Spanish
  return month;
};

export const CURRENT_YER = new Date().getFullYear();
export const FIRST_DAY_OF_YEAR = new Date(CURRENT_YER, 0, 1);
export const FIRST_DAY_OF_MONTH = new Date(
  CURRENT_YER,
  new Date().getMonth(),
  1
);
export const LAST_DAY_OF_MONTH = new Date(
  CURRENT_YER,
  new Date().getMonth() + 1,
  0
);
export const CURRENT_DAY = new Date(
  CURRENT_YER,
  new Date().getMonth(),
  new Date().getDate()
);
export const FIRST_DAY_OF_PREVIOUS_MONTH = new Date(
  CURRENT_YER,
  new Date().getMonth() - 1,
  1
);
export const LAST_DAY_OF_PREVIOUS_MONTH = new Date(
  CURRENT_YER,
  new Date().getMonth(),
  0
);
export const FIRST_DAY_OF_WEEK = new Date();
FIRST_DAY_OF_WEEK.setDate(
  FIRST_DAY_OF_WEEK.getDate() - FIRST_DAY_OF_WEEK.getDay()
);
export const LAST_DAY_OF_WEEK = new Date();
LAST_DAY_OF_WEEK.setDate(LAST_DAY_OF_WEEK.getDate() + 6);

export const TODAY = new Date();
