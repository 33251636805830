import { Card, DateRangePickerValue } from "@tremor/react";
import React, { createContext, useContext } from "react";
import { useGetBranches } from "../../api/endpoints/get-branches";

import { MultiSelect } from "../MultiSelect";
import { DatePicker } from "../ui/DatePicker";
import { useBranchesURLParam } from "../../hooks/use-branches-url-param";
import { useTimeFrameURLParam } from "../../hooks/use-time-frame-url-param";
import { Outlet } from "react-router-dom";

import "./base-provider.css";

const BLOCK = "base-provider";

interface BaseProviderContextType {
  branches: string[];
  from: string;
  to: string;
  timeFrame: DateRangePickerValue;
  setBranches: React.Dispatch<React.SetStateAction<string[]>>;
  setTimeFrame: React.Dispatch<React.SetStateAction<DateRangePickerValue>>;
}

const BaseProviderContext = createContext<BaseProviderContextType | undefined>(
  undefined
);

export const useBaseProviderContext = () => {
  const context = useContext(BaseProviderContext);
  if (!context) {
    throw new Error(
      "useBaseProviderContext must be used within a BaseProvider"
    );
  }
  return context;
};

export const BaseProvider = () => {
  const token = sessionStorage.getItem("itos_token");
  const { data, isLoading } = useGetBranches({
    enabled: token !== null || token !== "",
  });

  const { branches, setBranches } = useBranchesURLParam();
  const { from, to, timeFrame, onTimeFrameChange } = useTimeFrameURLParam();

  const { selectValues } = React.useMemo(() => {
    if (!data) {
      return { selectValues: [] };
    }

    return {
      selectValues: data.map((item) => ({
        value: item.id.toString(),
        label: item.name,
      })),
    };
  }, [data]);

  if (isLoading) {
    return <Card className="app">Loading...</Card>;
  }

  return (
    <div className="app">
      <div className={`${BLOCK}-menu`}>
        <div className={`${BLOCK}-selector`}>
          {selectValues.length > 1 && (
            <MultiSelect
              value={branches}
              values={selectValues}
              onValueChange={setBranches}
            />
          )}
        </div>
        <div className="col-span-2" />
        <div className="col-span-3">
          <DatePicker
            key="base-provider-date-picker"
            onValueChange={onTimeFrameChange}
            value={timeFrame}
          />
        </div>
      </div>
      <BaseProviderContext.Provider
        value={{
          from,
          to,
          timeFrame,
          setTimeFrame: onTimeFrameChange,
          setBranches,
          branches,
        }}
      >
        <div className="p-4" style={{ backgroundColor: "#f3f4f6" }}>
          <Outlet />
        </div>
      </BaseProviderContext.Provider>
    </div>
  );
};
