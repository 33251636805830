import { Outlet, redirect } from "react-router-dom";
import { APP_ROUTES } from "../../../routes";

export function loader() {
  const access_token = sessionStorage.getItem("itos_token");
  if (access_token !== null) {
    return redirect(APP_ROUTES.HOME);
  }
  return null;
}

export const UnprotectedRoutes = () => {
  return <Outlet />;
};
