import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

export interface MachineStatsByIDResponse {
  data: {
    id: number;
    machine_name: string;
    machine_type_name: string;
    total_sales: number;
    total_uses: number;
    branch_name: string;
  };
}
interface GetMachineStatsByIDProps extends BaseProps {
  id: number;
}
export const getMachineStatsByID = async ({
  id,
  from,
  to,
  branches,
}: GetMachineStatsByIDProps) => {
  let url = `/api/v1/machines/${id}/stats`;
  url = addBaseURLParams(url, { from, to, branches });
  const response = await ax
    .get<MachineStatsByIDResponse>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};
export const useGetMachineStatsByID = ({
  id,
  branches,
  from,
  to,
}: GetMachineStatsByIDProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["machines-stats-by-id", id, from, to, branches],
    queryFn: () => getMachineStatsByID({ id, branches, from, to }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data as MachineStatsByIDResponse["data"], isLoading };
};
