import { Chip } from "@mui/material";

export const StatusBadge = ({ status }: { status: boolean }) => {
  return (
    <Chip
      label={status ? "Activo" : "Inactivo"}
      color={status ? "success" : "error"}
      variant="outlined"
    />
  );
};
