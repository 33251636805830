import React from "react";
import { useGetOrg } from "../../api/endpoints/get-org";
import { BRANCHES_COLUMNS, USER_COLUMNS } from "./columns";
import { Table } from "../ui/Table/Table";

export const PageSettings = () => {
  const { data, isLoading } = useGetOrg({});
  const [userPaginationModel, setUserPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [branchPaginationModel, setBranchPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const { userColumns, userRows, branchColumns, branchRows } =
    React.useMemo(() => {
      if (!data) {
        return {
          userColumns: [],
          userRows: [],
          branchColumns: [],
          branchRows: [],
        };
      }

      return {
        branchColumns: BRANCHES_COLUMNS,
        branchRows: data.branches.map((item) => {
          return { id: item.name };
        }),
        userColumns: USER_COLUMNS,
        userRows: data.users.map((item) => {
          return { name: item.name, id: item.email };
        }),
      };
    }, [data]);

  return (
    <div className="p-4 m-4">
      <div className="mb-3 text-black-500 font-bold">Usuarios</div>
      <Table
        rows={userRows}
        columns={userColumns}
        isLoading={isLoading}
        paginationModel={userPaginationModel}
        setPaginationModel={setUserPaginationModel}
        totalRows={userRows.length}
        paginationMode="client"
        withToolbar={false}
      />
      <div className="mt-6 mb-3 text-black-500 font-bold">Sucursales</div>
      <Table
        rows={branchRows}
        columns={branchColumns}
        isLoading={isLoading}
        paginationModel={branchPaginationModel}
        setPaginationModel={setBranchPaginationModel}
        totalRows={userRows.length}
        paginationMode="client"
        withToolbar={false}
      />
    </div>
  );
};
