import React from "react";
import { RouteObject } from "react-router-dom";
import { App } from "./App";
import {
  UnprotectedRoutes,
  UnprotectedRoutesLoader,
} from "./components/authentication/UnprotectedRoutes";
import { ProtectedRoutes } from "./components/authentication/ProtectedRoutes";
import { Login, LoginAction } from "./components/authentication/Login";
import { APP_ROUTES } from "./routes";
import { PageSettings } from "./components/PageSettings/PageSettings";
import { PageMachines } from "./components/machines/PageMachines";
import { PageCustomers } from "./components/customers/PageCustomers";
import { PageRevenue } from "./components/revenue/PageRevenue";
import { BaseProvider } from "./components/BaseProvider";

export const router: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <UnprotectedRoutes />,
        loader: UnprotectedRoutesLoader,
        children: [
          {
            index: true,
            action: LoginAction,
            element: <Login />,
          },
        ],
      },
      {
        path: APP_ROUTES.HOME,
        element: <ProtectedRoutes />,
        children: [
          {
            element: <BaseProvider />,
            children: [
              {
                index: true,
                element: <PageRevenue />,
              },
              {
                path: APP_ROUTES.MACHINES,
                element: <PageMachines />,
              },
              {
                path: APP_ROUTES.CUSTOMERS,
                element: <PageCustomers />,
              },
            ],
          },
          {
            path: APP_ROUTES.SETTINGS,
            element: <PageSettings />,
          },
        ],
      },
    ],
  },
];
