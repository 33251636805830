import { Card, Flex } from "@tremor/react";
import { Callout } from "@tremor/react";
import { postLogin } from "../../../api/endpoints/login";
import { Form, redirect, useActionData } from "react-router-dom";
import { AppBar, Button, TextField, Toolbar, Typography } from "@mui/material";

export async function action({ request }: { request: Request }) {
  let formData = await request.formData();

  const email = String(formData.get("email"));
  const password = String(formData.get("password"));

  const data = await postLogin(email, password);
  if (!data?.isSuccess) {
    return {
      message: "Email o contraseña incorrectos, intenta de nuevo.",
    };
  }
  sessionStorage.setItem("itos_token", data.token);
  while (sessionStorage.getItem("itos_token") === null) {
    await new Promise((r) => setTimeout(r, 2000));
  }
  return redirect("/app", {});
}

export const Login = () => {
  const data = useActionData() as any;

  return (
    <Flex
      flexDirection={"col"}
      className="app"
      style={{ height: "100vh" }}
      alignItems="center"
      justifyContent="start"
    >
      <AppBar
        position="static"
        style={{ backgroundColor: "white", borderBottom: "1px solid #e5e7eb" }}
        elevation={0}
      >
        <Toolbar variant="dense">
          <Typography
            variant="h5"
            fontWeight={"bold"}
            color="#861b1e"
            component="div"
          >
            Mautra
          </Typography>
        </Toolbar>
      </AppBar>
      {data && (
        <div className="mx-auto max-w-lg space-y-6">
          <Callout title="Error" color="red">
            <p>
              Hubo un error al iniciar sesion, revise sus credenciales e intente
              de nuevo
            </p>
          </Callout>
        </div>
      )}
      <Card className="w-4/5 md:w-96" style={{ marginTop: "50px" }}>
        <h1 className="text-3xl font-bold text-center">Inicia sesión</h1>
        <Form className="grid gap-5 mt-10" method="post">
          <TextField
            id="email"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            name="email"
            type="email"
          />
          <TextField
            id="password"
            label="Contraseña"
            placeholder="Contraseña"
            name="password"
            type="password"
          />
          <Button type="submit" variant="contained">
            {" "}
            Submit{" "}
          </Button>
        </Form>
      </Card>
    </Flex>
  );
};
