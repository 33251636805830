import { Period } from "../../../utils/types";
import { MenuItem, Select } from "@mui/material";

export const PeriodSelector = ({
  period,
  setPeriod,
}: {
  period: Period;
  setPeriod: (period: Period) => void;
}) => {
  return (
    <Select
      id="period"
      label="Periodo"
      value={period}
      onChange={(e) => setPeriod(e.target.value as Period)}
    >
      <MenuItem value="day">Día</MenuItem>
      <MenuItem value="day_of_week">Día de la semana</MenuItem>
      <MenuItem value="month">Mes</MenuItem>
      <MenuItem value="hour">Hora</MenuItem>
    </Select>
  );
};
