import React from "react";
import { useGetCustomers } from "../../../api/endpoints/get-customers";
import { CustomersTable } from "../CustomersTable";
import { useGetCustomerStats } from "../../../api/endpoints/get-customet-stats";
import { NumberCard } from "../../ui/NumberCard";
import { useTimeFrame } from "../../../hooks/use-time-frame";
import { useBranches } from "../../../hooks/use-branches";

export const PageCustomers = () => {
  const { from, to } = useTimeFrame();
  const { branches } = useBranches();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const { data, isLoading } = useGetCustomers({
    branches,
    from,
    to,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  });
  const { data: statsData, isLoading: statsIsLoading } = useGetCustomerStats({
    branches,
    from,
    to,
  });

  return (
    <div>
      <div className="grid lg:grid-flow-col lg:grid-cols-4 gap-5 mb-4">
        <NumberCard
          isLoading={statsIsLoading}
          amount={statsData?.total_visits ?? 0}
          title={"Total de visitas en este rango"}
        />
        <NumberCard
          isLoading={statsIsLoading}
          amount={statsData?.unique_visits ?? 0}
          title={"Clientes únicos en este rango"}
        />
        <NumberCard
          isLoading={statsIsLoading}
          amount={statsData?.avg_uses_per_customer.toFixed(2) ?? 0}
          title={"Promedio de usos por cliente"}
        />
      </div>
      <CustomersTable
        customers={data?.data}
        totalCount={data?.meta.total_count ?? 0}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isLoading={isLoading}
      />
    </div>
  );
};
