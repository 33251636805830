import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Navbar } from "../../Navbar";

export const ProtectedRoutes = () => {
  const [accessToken, setAccessToken] = useState<string>(
    sessionStorage.getItem("itos_token") || ""
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const token = sessionStorage.getItem("itos_token");
      setAccessToken(token ?? "");
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!accessToken || accessToken === "") {
    return <Navigate to={"/"} />;
  }

  return (
    <Navbar>
      <Outlet />
    </Navbar>
  );
};
