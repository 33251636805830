import { instance as ax } from "../setup";

export const postLogin = async (email: string, password: string) => {
  let url = "/login";
  const response = await ax
    .post(
      url,
      { email, password },
      { headers: { "content-type": "multipart/form-data" } }
    )
    .then((response) => {
      if (response.status === 200) {
        return { isSuccess: true, token: response.data.token };
      }

      return { isSuccess: false, token: null };
    })
    .catch(() => {
      return undefined;
    });

  return response;
};
