import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { StatusBadge } from "../../ui/StatusBadge";

const COLUMNS: GridColDef<any>[] = [
  {
    field: "occurred_at",
    headerName: "Fecha",
    flex: 1,
  },
  {
    field: "is_active",
    headerName: "Estado",
    flex: 1,
    renderCell: (
      params: GridRenderCellParams<{ name: string; color: string }, any, any>
    ) => {
      return <StatusBadge status={params.value} />;
    },
  },
];

export const getColumns = () => COLUMNS;
