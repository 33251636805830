import React from "react";

import { MachineSalesTable } from "../MachineSalesTable/MachineSalesTable";
import { Drawer } from "@mui/material";
import { MachineDetail } from "../MachineDetail";
import { useTimeFrame } from "../../../hooks/use-time-frame";
import { useBranches } from "../../../hooks/use-branches";
import { MachineSalesSummary } from "../MachineSalesSummary";
import { BasicTabs } from "../../ui/Tabs";

export const PageMachines = () => {
  const { from, to } = useTimeFrame();
  const { branches } = useBranches();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<number>(0);
  const [machineID, setMachineID] = React.useState<number | undefined>(
    undefined
  );

  return (
    <div className="w-auto">
      <BasicTabs
        tabs={[
          {
            label: "Resumen",
            content: (
              <MachineSalesSummary branches={branches} from={from} to={to} />
            ),
          },
          {
            label: "Máquinas",
            content: (
              <MachineSalesTable
                branches={branches}
                from={from}
                to={to}
                onRowClick={(params) => {
                  setOpenDrawer(true);
                  setMachineID(params.row.id);
                }}
              />
            ),
          },
        ]}
        value={tab}
        onValueChange={(_, tab) => setTab(tab)}
      />
      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <MachineDetail
          id={machineID}
          onClose={() => setOpenDrawer(false)}
          branches={branches}
          from={from}
          to={to}
        />
      </Drawer>
    </div>
  );
};
