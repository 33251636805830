import {
  DateRangePicker,
  DateRangePickerItem,
  DateRangePickerValue,
} from "@tremor/react";
import { es } from "date-fns/locale";
import {
  CURRENT_DAY,
  FIRST_DAY_OF_MONTH,
  FIRST_DAY_OF_PREVIOUS_MONTH,
  FIRST_DAY_OF_WEEK,
  FIRST_DAY_OF_YEAR,
  LAST_DAY_OF_PREVIOUS_MONTH,
  LAST_DAY_OF_WEEK,
  TODAY,
} from "../../../utils/date";

interface DatePickerProps {
  key: string;
  value: DateRangePickerValue;
  onValueChange: (value: DateRangePickerValue) => void;
}

export const DatePicker = ({ key, value, onValueChange }: DatePickerProps) => {
  return (
    <DateRangePicker
      id={key}
      key={key}
      value={value}
      onValueChange={onValueChange}
      locale={es}
      selectPlaceholder="Seleccionar"
      enableClear={false}
      enableYearNavigation
    >
      <DateRangePickerItem key="day" value="day" from={TODAY} to={TODAY}>
        {" "}
        Hoy{" "}
      </DateRangePickerItem>
      <DateRangePickerItem
        key="week"
        value="week"
        from={FIRST_DAY_OF_WEEK}
        to={LAST_DAY_OF_WEEK}
      >
        {" "}
        Esta semana{" "}
      </DateRangePickerItem>
      <DateRangePickerItem
        key="month"
        value="month"
        from={FIRST_DAY_OF_MONTH}
        to={CURRENT_DAY}
      >
        {" "}
        Este mes{" "}
      </DateRangePickerItem>
      <DateRangePickerItem
        key="lastmonth"
        value="lastmonth"
        from={FIRST_DAY_OF_PREVIOUS_MONTH}
        to={LAST_DAY_OF_PREVIOUS_MONTH}
      >
        {" "}
        Mes pasado{" "}
      </DateRangePickerItem>
      <DateRangePickerItem key="ytd" value="ytd" from={FIRST_DAY_OF_YEAR}>
        {" "}
        YTD{" "}
      </DateRangePickerItem>
    </DateRangePicker>
  );
};
