import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";

interface Response {
  data: {
    id: number;
    name: string;
  }[];
}

export const getBranches = async () => {
  let url = "/api/v1/me/branches";
  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

interface GetBranchesProps {
  enabled?: boolean;
}

let retries = 0;

export const useGetBranches = ({ enabled }: GetBranchesProps) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["branches"],
    queryFn: getBranches,
    enabled,
  });

  if (!data && !isLoading && retries < 3) {
    refetch();
    retries++;
  }

  if (!data) {
    return { branches: [], isLoading };
  }

  return { data: data.data, isLoading };
};
