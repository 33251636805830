import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

interface Movement {
  amount: number;
  fetched_at: string;
  created_at: string;
  data: string;
  is_credit_card: boolean;
  id: number;
  branch_id: number;
  type: string;
}
interface Response {
  data: Movement[];
}

export const getRevenueTransactions = async ({
  from,
  to,
  branches,
}: BaseProps) => {
  let url = "/api/v1/movements";
  url = addBaseURLParams(url, { from, to, branches });

  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data.data.map((item) => {
        const { amount, ...rest } = item;
        return {
          ...rest,
          amount: Number(amount),
        };
      });
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });
  return response;
};

interface RevenueTransactionsProps extends BaseProps {}

export const useRevenueTransactions = ({
  branches,
  from,
  to,
}: RevenueTransactionsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["revenueTransactions", from, to, branches],
    queryFn: () => getRevenueTransactions({ branches, from, to }),
  });
  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data, isLoading };
};
