import React from "react";
import { CashVSCreditByDay } from "../CashVsCreditByDay";
import { TotalOperations } from "../TotalOperations";
import { useStats } from "../../../api/endpoints";
import { formatToMXN } from "../../../utils/money";
import { NumberCard } from "../../ui/NumberCard";
import { useTimeFrame } from "../../../hooks/use-time-frame";
import { useBranches } from "../../../hooks/use-branches";
import { RevenueTimeSeries } from "../RevenueTimeSeries";

export const PageRevenue = () => {
  const { from, to } = useTimeFrame();
  const { branches } = useBranches();
  const { data, isLoading } = useStats({
    branches,
    from,
    to,
  });
  return (
    <React.Fragment>
      <div className="grid lg:grid-flow-col lg:grid-cols-5 gap-5 mb-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.total_amount ?? 0)}
          title="Total ingresos en el kiosko"
          isDark
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.avg_daily_sales ?? 0)}
          title="Promedio ingresos por día"
        />
        <NumberCard
          amount={data?.credit_card_percentage.toFixed(2) ?? 0}
          isLoading={isLoading}
          title={"% Tarjeta de crédito"}
        />
        <NumberCard
          amount={data?.cash_percentage.toFixed(2) ?? 0}
          isLoading={isLoading}
          title={"% Efectivo"}
        />
        <TotalOperations branches={branches} from={from} to={to} />
      </div>
      <div className="grid lg:grid-flow-col lg:grid-cols-5 gap-5 my-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.avg_operation_amount ?? 0)}
          title="Promedio operación kiosko"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.laundry_sales ?? 0)}
          title="Venta en usos de lavandería"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.water_sales ?? 0)}
          title="Venta en usos de agua"
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.last_movement ?? ""}
          title="Último movimiento"
        />
      </div>
      <div className="lg:grid grid-flow-col grid-cols-2 gap-5 my-4">
        <RevenueTimeSeries branches={branches} from={from} to={to} />
        <CashVSCreditByDay branches={branches} from={from} to={to} />
      </div>
    </React.Fragment>
  );
};
