import React from "react";
import { BaseProps, Period } from "../../../utils/types";
import { Graph } from "../../ui/Graph";
import { GraphContainer } from "../../ui/GraphContainer";
import { PRIMARY_COLOR } from "../../../utils/colors";
import { formatToMXN } from "../../../utils/money";
import { PeriodSelector } from "../../ui/PeriodSelector";
import { useRevenueTimeSeries } from "../../../api/endpoints/get-revenue-timeseries";
import { getXAxisValueFormatterForPeriod } from "../../../utils/charts";
const DEFAULT_COLORS = [PRIMARY_COLOR];

const getTitle = (period: string) => {
  switch (period) {
    case "day":
      return "Ventas por día";
    case "day_of_week":
      return "Ventas por día de la semana";
    case "month":
      return "Ventas por mes";
    case "hour":
      return "Ventas por hora";
    default:
      return "";
  }
};

interface RevenueTimeSeriesGraphProps extends BaseProps {
  width?: number;
  colors?: string[];
}
export const RevenueTimeSeries = ({
  branches,
  from,
  to,
  width,
  colors = DEFAULT_COLORS,
}: RevenueTimeSeriesGraphProps) => {
  const [period, setPeriod] = React.useState<Period>("day");
  const { data, isLoading } = useRevenueTimeSeries({
    branches: branches,
    from: from,
    to: to,
    period,
  });

  const { keys, series } = React.useMemo(() => {
    if (!data || isLoading) {
      return {
        keys: [],
        series: [],
      };
    }

    const sales = data.reduce((acc, item) => {
      if (!acc[item.time_period]) {
        acc[item.time_period] = 0;
      }
      acc[item.time_period] += item.total_sales;
      return acc;
    }, {} as Record<string, number>);

    return {
      keys: Object.keys(sales),
      series: Object.values(sales),
    };
  }, [data, isLoading]);
  return (
    <GraphContainer>
      <Graph
        key="machine-sales-by-period-graph"
        data={{
          xAxis: [{ keys }],
          series: [{ data: series, label: "Ventas" }],
        }}
        title={getTitle(period)}
        rightTitleComponent={
          <PeriodSelector period={period} setPeriod={setPeriod} />
        }
        colors={colors}
        width={width}
        xAxisValueFormatter={getXAxisValueFormatterForPeriod(period)}
        seriesValueFormatter={(value) => formatToMXN(value)}
      />
    </GraphContainer>
  );
};
