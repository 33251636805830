import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useUrlParam } from "./use-url-param";

const BRANCHES_PARAM = "branches";

export const useBranchesURLParam = () => {
  const [searchParams] = useSearchParams();
  const [branches, setBranches] = useState<string[]>(
    searchParams.get(BRANCHES_PARAM)?.split(",") ?? []
  );

  useUrlParam(BRANCHES_PARAM, branches);

  return {
    branches,
    setBranches,
  };
};
