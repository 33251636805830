export const USER_COLUMNS = [
  {
    field: "id",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
  },
];

export const BRANCHES_COLUMNS = [
  {
    field: "id",
    headerName: "Nombre",
    flex: 1,
  },
];
