import { FC, ReactNode } from "react";

import "./side-panel.css";

const BLOCK = "side-panel";

interface SidePanelProps {
  children?: ReactNode;
  className?: string;
}

export const SidePanel: FC<SidePanelProps> = ({ children, className }) => {
  return <div className={`${BLOCK} ${className}`}>{children}</div>;
};
