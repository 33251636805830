import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetOrg } from "../../api/endpoints/get-org";
import { BottomNavigationAction, Paper, SvgIconTypeMap } from "@mui/material";
import { APP_ROUTES } from "../../routes";
import { logout } from "../../utils/logout";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import GroupIcon from "@mui/icons-material/Group";
import BottomNavigation from "@mui/material/BottomNavigation";

import "./navbar.css";

const BLOCK = "navbar";
const DRAWER_WIDTH = 200;

type NavItem = {
  id: string;
  text: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
  route: string;
};

const MENU_ITEMS: NavItem[] = [
  {
    id: "dashboard",
    text: "Dashboard",
    Icon: DashboardIcon,
    route: APP_ROUTES.HOME,
  },
  {
    id: "machines",
    text: "Máquinas",
    Icon: LocalLaundryServiceIcon,
    route: APP_ROUTES.MACHINES,
  },
  {
    id: "customers",
    text: "Clientes",
    Icon: GroupIcon,
    route: APP_ROUTES.CUSTOMERS,
  },
];

const SUB_MENU_ITEMS: NavItem[] = [
  {
    id: "settings",
    text: "Cuenta",
    Icon: SettingsIcon,
    route: APP_ROUTES.SETTINGS,
  },
];

const PAGE_KEY_TO_MENU_ID = {
  app: "dashboard",
  machines: "machines",
  customers: "customers",
  settings: "settings",
};

interface NavbarProps {
  children?: React.ReactNode;
}

export const Navbar = ({ children }: NavbarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const key = React.useMemo(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean); // Removes empty segments
    return pathSegments[pathSegments.length - 1];
  }, [location]);
  const [selectedNavItem, setSelectedNavItem] = React.useState<string>(
    PAGE_KEY_TO_MENU_ID[key as keyof typeof PAGE_KEY_TO_MENU_ID]
  );
  const { data, isLoading } = useGetOrg();

  const drawer = (
    <List className={`${BLOCK}_list`}>
      <div className={`${BLOCK}_org-title px-5 pt-2 pb-6`}>
        {isLoading ? (
          <p>...</p>
        ) : (
          <span className={` font-semibold`}>{data?.org_name}</span>
        )}
      </div>
      {MENU_ITEMS.map(({ id, text, Icon, route }) => (
        <NavButton
          Icon={Icon}
          text={text}
          onClick={() => {
            setSelectedNavItem(id);
            navigate(route);
          }}
          isSelected={selectedNavItem === id}
        />
      ))}
      <div className={`${BLOCK}_divider`} />
      {SUB_MENU_ITEMS.map(({ id, text, Icon, route }) => (
        <NavButton
          Icon={Icon}
          text={text}
          onClick={() => {
            setSelectedNavItem(id);
            navigate(route);
          }}
          isSelected={selectedNavItem === id}
        />
      ))}
      <NavButton
        Icon={LogoutIcon}
        text="Cerrar sesión"
        onClick={async () => await logout(navigate)}
      />
    </List>
  );

  const bottomMenu = (
    <BottomNavigation
      value={selectedNavItem}
      onChange={(_, newValue) => {
        setSelectedNavItem(newValue);
      }}
      showLabels
      sx={{
        display: { xs: "flex", md: "none" },
        width: "100%",
      }}
    >
      {MENU_ITEMS.map(({ id, text, Icon, route }) => (
        <BottomNavigationAction
          label={text}
          icon={<Icon />}
          value={id}
          onClick={() => {
            setSelectedNavItem(id);
            navigate(route);
          }}
        />
      ))}
      {SUB_MENU_ITEMS.map(({ id, text, Icon, route }) => (
        <BottomNavigationAction
          label={text}
          icon={<Icon />}
          value={id}
          onClick={() => {
            setSelectedNavItem(id);
            navigate(route);
          }}
        />
      ))}
    </BottomNavigation>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Desktop navbar */}
      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* Content */}
      <div className="w-full">{children}</div>
      {/* Mobile bottom navbar */}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        {bottomMenu}
      </Paper>
    </Box>
  );
};

function NavButton({
  Icon,
  text,
  onClick,
  isSelected,
}: {
  Icon: OverridableComponent<SvgIconTypeMap>;
  text: string;
  onClick: () => void;
  isSelected?: boolean;
}) {
  return (
    <ListItem className={`${BLOCK}_nav-item`}>
      <ListItemButton
        onClick={onClick}
        className={`${BLOCK}_nav-button${isSelected ? "-selected" : ""}`}
      >
        <Icon
          className={`${BLOCK}_nav-icon${
            isSelected ? "-selected" : ""
          } mr-3 text-black-500`}
          style={{ fontSize: 20 }}
        />
        <Typography variant="subtitle1">{text}</Typography>
      </ListItemButton>
    </ListItem>
  );
}
