import React from "react";

import "./graph-container.css";

const BLOCK = "graph-container";

interface GraphContainerProps {
  children?: React.ReactNode;
  className?: string;
}
export const GraphContainer = ({
  children,
  className,
}: GraphContainerProps) => {
  return <div className={`${BLOCK} ${className}`}>{children}</div>;
};
