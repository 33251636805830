import { MachineStatus } from "../../../api/endpoints/get-machine-statuses";
import { Table } from "../../ui/Table";
import React from "react";
import { getColumns } from "../MachineStatusesTable/columns";

interface MachineStatusesTableProps {
  data: MachineStatus[] | undefined;
  isLoading: boolean;
}

export const MachineStatusesTable = ({
  data,
  isLoading,
}: MachineStatusesTableProps) => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  return (
    <Table
      getRowId={(row) => row.occurred_at}
      columns={getColumns()}
      rows={data ?? []}
      isLoading={isLoading}
      totalRows={data?.length ?? 0}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
    />
  );
};
