import axios from "axios";

const PROD_URL = "https://api.mautra-analytics.com";
const DEV_URL = "http://localhost:4000";

export const instance = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? PROD_URL : DEV_URL,
  timeout: 5000, // 5 seconds
});

instance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("itos_token");
  const tokenString = token ? `Bearer ${token}` : "";
  instance.defaults.headers.common["Authorization"] = tokenString;
  return config;
});
