import {
  MultiSelect as MultiSelectTremor,
  MultiSelectItem,
} from "@tremor/react";

export interface MultiSelectValue {
  value: string;
  label: string;
}

interface MultiSelectProps {
  value: string[];
  onValueChange: (value: string[]) => void;
  values: MultiSelectValue[];
}

export const MultiSelect = ({
  value,
  onValueChange,
  values,
}: MultiSelectProps) => {
  return (
    <MultiSelectTremor value={value} onValueChange={onValueChange}>
      {values.map((item) => (
        <MultiSelectItem key={item.value} value={item.value}>
          {item.label}
        </MultiSelectItem>
      ))}
    </MultiSelectTremor>
  );
};
