import { BaseProps } from "../../utils/types";

export const addBaseURLParams = (
  url: string,
  { from, to, branches }: BaseProps
) => {
  if (from && to) {
    url += `?from_date=${from}&to_date=${to}`;
  }

  if (branches.length > 0 && from && to) {
    url += `&branch_ids=${branches.join(",")}`;
  } else if (branches.length > 0) {
    url += `?branch_ids=${branches.join(",")}`;
  }
  return url;
};
