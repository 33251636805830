import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { formatToMXN } from "../../../utils/money";

import { StatusBadge } from "../../ui/StatusBadge";

const COLUMNS: GridColDef<any>[] = [
  {
    field: "machine_name",
    headerName: "Nombre",
    flex: 1,
  },
  {
    field: "machine_type_name",
    headerName: "Tipo",
    flex: 1,
  },
  {
    field: "sales",
    headerName: "Ventas",
    valueFormatter: (value: number) => formatToMXN(value),
    flex: 1,
  },
  {
    field: "uses",
    headerName: "Usos",
    flex: 1,
  },
  {
    field: "branch_name",
    headerName: "Sucursal",
    flex: 1,
  },
  {
    field: "is_active",
    headerName: "Estado",
    flex: 1,
    renderCell: (
      params: GridRenderCellParams<{ name: string; color: string }, any, any>
    ) => {
      return <StatusBadge status={params.value} />;
    },
  },
];

export const getColumns = () => COLUMNS;
