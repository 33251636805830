import React from "react";
import { Table } from "../../ui/Table/Table";

import { getColumns } from "./columns";
import { GridEventListener } from "@mui/x-data-grid";
import { useGetMachineStats } from "../../../api/endpoints";
import { BaseProps } from "../../../utils/types";

interface MachineSalesTableProps extends BaseProps {
  onRowClick: GridEventListener<"rowClick">;
}
export const MachineSalesTable = ({
  branches,
  from,
  to,
  onRowClick,
}: MachineSalesTableProps) => {
  const { data: machineSales, isLoading } = useGetMachineStats({
    branches,
    from,
    to,
  });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 15,
  });

  const { columns, rows } = React.useMemo(() => {
    if (!machineSales) {
      return { columns: [], rows: [] };
    }

    const rows = machineSales.machine_sales.map((item) => {
      return {
        id: item.id,
        machine_name: item.machine_name,
        machine_type_name: item.machine_type_name,
        sales: item.sales,
        uses: item.uses,
        branch_name: item.branch_name,
        is_active: item.is_active,
      };
    });

    return { columns: getColumns(), rows };
  }, [machineSales]);

  return (
    <div className="h-screen">
      <Table
        columns={columns}
        rows={rows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        totalRows={machineSales?.machine_sales.length ?? 0}
        isLoading={isLoading}
        onRowClick={onRowClick}
        getRowId={(row) => row.id}
        paginationMode="client"
      />
    </div>
  );
};
