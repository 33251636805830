import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

export interface MachineStatus {
  machine_id: number;
  is_active: boolean;
  occurred_at: string;
}

interface Response {
  data: MachineStatus[];
}

interface GetMachineStatusesProps extends BaseProps {
  machineIds: number[] | undefined;
}

export const getMachineStatuses = async ({
  from,
  to,
  branches,
  machineIds,
}: GetMachineStatusesProps) => {
  if (!machineIds) {
    return null;
  }

  let url = "/api/v1/machines/statuses";
  url = addBaseURLParams(url, { from, to, branches });
  url += `&machine_ids=${machineIds.join(",")}`;
  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

export const useGetMachineStatuses = ({
  branches,
  from,
  to,
  machineIds,
}: GetMachineStatusesProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["machines-statuses", from, to, branches, machineIds],
    queryFn: () => getMachineStatuses({ branches, from, to, machineIds }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data, isLoading };
};
