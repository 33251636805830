import { useRevenueTransactions } from "../../../api/endpoints/get-revenue-transactions";
import { BaseProps } from "../../../utils/types";
import { NumberCard } from "../../ui/NumberCard";

interface TotalOperationsProps extends BaseProps {}

export const TotalOperations = ({
  branches,
  from,
  to,
}: TotalOperationsProps) => {
  const { data, isLoading } = useRevenueTransactions({ branches, from, to });

  return (
    <NumberCard
      isLoading={isLoading}
      amount={data?.length ?? 0}
      title="Total de operaciones"
    />
  );
};
