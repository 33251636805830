import React from "react";
import { Customer } from "../../../api/endpoints/get-customers";
import { Skeleton } from "@mui/material";
import { Table } from "../../ui/Table/Table";
import { COLUMNS } from "./columns";
import { GridCallbackDetails, GridPaginationModel } from "@mui/x-data-grid";
import { formatToLongDate } from "../../../utils/date";

interface CustomersTableProps {
  customers: Customer[] | undefined;
  totalCount: number;
  paginationModel: GridPaginationModel;
  setPaginationModel: (
    model: GridPaginationModel,
    details: GridCallbackDetails<any>
  ) => void;
  isLoading: boolean;
}
export const CustomersTable = ({
  customers,
  totalCount,
  isLoading,
  paginationModel,
  setPaginationModel,
}: CustomersTableProps) => {
  const { columns, rows } = React.useMemo(() => {
    if (!customers) {
      return { columns: [], rows: [] };
    }

    const columns = COLUMNS;
    const rows = customers.map((customer) => {
      return {
        id: customer.phone,
        lastMovement: customer.last_movement
          ? formatToLongDate(customer.last_movement)
          : "N/A",
        amountSpent: customer.amount_spent,
        totalUses: customer.total_uses,
      };
    });

    return { columns, rows };
  }, [customers]);

  if (!customers) {
    return (
      <div>
        {[...Array(10)].map((_, index) => (
          <Skeleton key={index} className="mx-2" variant="text" height={40} />
        ))}
      </div>
    );
  }

  return (
    <div>
      <Table
        columns={columns}
        rows={rows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        totalRows={totalCount}
        isLoading={isLoading}
        withToolbar={false}
      />
    </div>
  );
};
