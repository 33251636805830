import { NumberCard } from "../../ui/NumberCard";
import { MachineStatsByIDResponse } from "../../../api/endpoints/get-machine-stats-by-id";
import { useGetMachineStatuses } from "../../../api/endpoints/get-machine-statuses";
import { BaseProps } from "../../../utils/types";
import { formatToMXN } from "../../../utils/money";
import { MachineStatusesTable } from "../MachineStatusesTable";

interface MachineDetailSummaryProps extends BaseProps {
  id?: number;
  data?: MachineStatsByIDResponse["data"];
  isLoading: boolean;
}
export const MachineDetailSummary = ({
  id,
  data,
  isLoading,
  branches,
  from,
  to,
}: MachineDetailSummaryProps) => {
  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetMachineStatuses({
      branches,
      from,
      to,
      machineIds: id ? [id] : undefined,
    });
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <NumberCard
          isLoading={isLoading}
          title={"Sucursal"}
          amount={data?.branch_name ?? ""}
        />
        <NumberCard
          isLoading={isLoading}
          title={"Tipo de máquina"}
          amount={data?.machine_type_name ?? ""}
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.total_sales ?? 0)}
          title={"Ventas"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.total_uses ?? 0}
          title="Usos"
        />
      </div>
      <div className="mt-4">
        <div className="mb-3 text-black-500 font-bold">
          Historial de estados
        </div>
        <MachineStatusesTable data={statuses} isLoading={isLoadingStatuses} />
      </div>
    </div>
  );
};
