import { CircularProgress } from "@mui/material";

import "./number-card.css";

const BLOCK = "number-card";

interface NumberCardProps {
  isLoading: boolean;
  amount: number | string;
  title: string;
  isDark?: boolean;
}

export const NumberCard = ({
  isLoading,
  amount,
  title,
  isDark,
}: NumberCardProps) => {
  const classN = isDark ? `${BLOCK}-dark` : `${BLOCK}`;
  return (
    <div className={`${BLOCK}-base ${classN} mx-auto p-5`}>
      <h4 className="text-sm font-bold">{title}</h4>
      {isLoading ? (
        <CircularProgress color="secondary" size={30} />
      ) : (
        <p className="text-base font-normal">
          <div>{amount}</div>
        </p>
      )}
    </div>
  );
};
