import { GridColDef } from "@mui/x-data-grid";
import { formatToMXN } from "../../../utils/money";

export const COLUMNS: GridColDef<any>[] = [
  {
    field: "id",
    headerName: "Télefono",
    flex: 1,
    sortable: false,
  },
  {
    field: "lastMovement",
    headerName: "Última visita",
    flex: 1,
    sortable: false,
  },
  {
    field: "amountSpent",
    description: "Gastos totales en el rango de tiempo seleccionado",
    headerName: "Gastos",
    valueFormatter: (value: number) => formatToMXN(value),
    flex: 1,
    sortable: false,
  },
  {
    field: "totalUses",
    description: "Número total de usos en el rango de tiempo seleccionado",
    headerName: "Usos",
    flex: 1,
    sortable: false,
  },
];
