import React from "react";
import { useRevenueTransactions } from "../../../api/endpoints/get-revenue-transactions";
import { formatDate } from "../../../utils/date";
import { BaseProps } from "../../../utils/types";
import { LineChart } from "@mui/x-charts/LineChart";
import { SECONDARY_COLOR, TERTIARY_COLOR } from "../../../utils/colors";
import { SLOT_PROPS } from "../../../utils/charts";
import { GraphContainer } from "../../ui/GraphContainer";

interface CashVSCreditByDayProps extends BaseProps {}

const sortDate = (
  a: { date: string; efectivo: number; tarjeta: number },
  b: { date: string; efectivo: number; tarjeta: number }
) => {
  return a.date.localeCompare(b.date);
};

export const CashVSCreditByDay = ({
  branches,
  from,
  to,
}: CashVSCreditByDayProps) => {
  const { data, isLoading } = useRevenueTransactions({ branches, from, to });

  const { values, keys } = React.useMemo(() => {
    if (!data || isLoading) {
      return { keys: [], values: [] };
    }

    const grouped = data.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = { cashAmount: 0, creditAmount: 0 };
      }

      if (item.is_credit_card) {
        acc[date].creditAmount += item.amount;
      } else {
        acc[date].cashAmount += item.amount;
      }
      return acc;
    }, {} as Record<string, { cashAmount: number; creditAmount: number }>);

    return {
      keys: Object.keys(grouped),
      values: Object.values(grouped),
    };
  }, [data, isLoading]);

  return (
    <GraphContainer>
      <h3 className="text-base font-bold"> Metodo de pago por dia </h3>
      <LineChart
        slotProps={SLOT_PROPS}
        xAxis={[
          {
            scaleType: "point",
            data: keys,
          },
        ]}
        series={[
          {
            data: values.map((v) => v.cashAmount),
            area: true,
          },
          {
            data: values.map((v) => v.creditAmount),
            area: true,
          },
        ]}
        grid={{
          horizontal: true,
        }}
        height={350}
        colors={[SECONDARY_COLOR, TERTIARY_COLOR]}
      />
    </GraphContainer>
  );
};
