import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";

interface Response {
  data: {
    org_id: number;
    org_name: string;
    users: {
      name: string;
      email: string;
    }[];
    branches: {
      id: number;
      name: string;
    }[];
  };
}

export const getOrg = async () => {
  let url = "/api/v1/me/org";
  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

interface GetOrgProps {
  enabled?: boolean;
}

let retries = 0;

export const useGetOrg = ({ enabled }: GetOrgProps = {}) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["me-org"],
    queryFn: getOrg,
    enabled,
  });

  if (!data && !isLoading && retries < 3) {
    refetch();
    retries++;
  }

  if (!data) {
    return { data: undefined, isLoading };
  }

  return { data: data.data, isLoading };
};
