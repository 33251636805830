import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

export interface MachineStats {
  id: number;
  machine_name: string;
  machine_type_name: string;
  branch_name: string;
  sales: number;
  uses: number;
  is_active?: boolean;
}

interface Response {
  data: {
    machine_sales: MachineStats[];
    total_machines: number;
    washer_sales: number;
    washer_uses: number;
    total_washers: number;
    dryer_sales: number;
    dryer_uses: number;
    total_dryers: number;
  };
}

export const getMachineStats = async ({ from, to, branches }: BaseProps) => {
  let url = "/api/v1/machines/stats";
  url = addBaseURLParams(url, { from, to, branches });
  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

export const useGetMachineStats = ({ branches, from, to }: BaseProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["machines-stats", from, to, branches],
    queryFn: () => getMachineStats({ branches, from, to }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data, isLoading };
};
